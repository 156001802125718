var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "retur-modal",
      "title": "Form Request Pengembalian"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Request Pengembalian")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Item"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.items.length
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Gudang"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.id_gudang
    },
    model: {
      value: _vm.form.id_gudang,
      callback: function ($$v) {
        _vm.$set(_vm.form, "id_gudang", $$v);
      },
      expression: "form.id_gudang"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Keterangan (optional)"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }