var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('a', {
    staticClass: "mb-1",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('view', {
          component: 'ListPengembalian',
          pengembalian: null
        });
      }
    }
  }, [_vm._v("Kembali")]), _c('b-card', [_c('span', {
    staticClass: "text-secondary"
  }, [_vm._v(_vm._s(_vm.item.no))]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Detail Pengembalian")]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sales"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.sales ? _vm.item.sales.nama_lengkap : _vm.item.id_sales))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Request Pengembalian"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.humanDate(_vm.item.tanggal)))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.keterangan))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan Pengembalian"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.gudang ? _vm.item.gudang.nama_gudang : _vm.item.id_gudang))])])], 1)], 1)], 1), _c('h3', [_vm._v("Rincian Barang "), _vm.item.rincian.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.item.rincian.length) + ")")]) : _vm._e()]), _c('b-card', [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.item.rincian
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(_vm._s(++index))];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.barang ? _c('span', [_vm._v(" " + _vm._s(item.barang.nama) + " ")]) : _c('strong', [_c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ditemukan")])])];
      }
    }, {
      key: "cell(status)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.status && item.status.approve ? _c('feather-icon', {
          staticClass: "text-success",
          attrs: {
            "size": "24",
            "icon": "CheckCircleIcon"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.barang ? _c('span', [_vm._v(" " + _vm._s(item.barang.satuan ? item.barang.satuan.satuan : '-') + " ")]) : _c('strong', [_c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ditemukan")])])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref5) {
        var index = _ref5.index,
          item = _ref5.item;
        return [_vm.isSales && _vm.myGudang.id && item.status && !item.status.approve ? _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeRincian(item, index);
            }
          }
        }) : _c('feather-icon', {
          staticClass: "text-secondary",
          attrs: {
            "icon": "SlashIcon",
            "size": "24"
          }
        })];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }