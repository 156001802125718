<template>
    <main>
        <component @view="changeComponent" :pengembalian="item" :is="currentComponent"></component>
    </main>
</template>
<script>
import ListPengembalian from './ListPengembalian.vue'
import DetailPengembalian from './DetailPengembalian.vue'
export default {
    components: {
        ListPengembalian,
        DetailPengembalian
    },
    data: () => ({
        currentComponent: 'ListPengembalian',
        item: {}
    }),
    methods: {
        changeComponent({component, pengembalian}) {
            this.item = pengembalian
            this.currentComponent = component
        }
    }
}
</script>